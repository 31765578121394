<template>
  <div class="confirm_box posr">
    <div
      class="confirm_con"
      :class="{ confirm_con_old: isOldCase }"
      :style="{
        width: !showMaterial ? '4rem' : '5rem',
        minHeight: !showMaterial ? '2.68rem' : '3.68rem',
      }"
    >
      <div class="con_pic con_pic_warning" v-if="showPic"></div>
      <p
        :class="{ main_theme_color_333: isOldCase }"
        class="con_tip"
        v-if="typeof confirmObj.tipText === 'string'"
      >
        {{ confirmObj.tipText }}
      </p>
      <div v-if="Array.isArray(confirmObj.tipText)">
        <p
          class="con_txt"
          v-for="(item, index) in confirmObj.tipText"
          :key="`t_${index}`"
        >
          {{ item }}
        </p>
      </div>
      <p
        v-if="confirmObj.initText"
        style="font-size: 0.14rem !important; line-height: 0.2rem"
        class="mt2rem main_theme_color_999"
      >
        {{ confirmObj.initText }}
      </p>
      <div class="material" v-if="showMaterial">
        <div class="combined">
          <div class="combined-title">膜片组合：</div>
          <div>
            <span class="fz_20">{{ showMaterial }}</span>
            <!-- <span class="material-detail" v-for="e in showMaterial" :key="e.id"> {{ e.name }} </span> -->
          </div>
        </div>
        <div style="padding-left: 1rem" class="mt10">
          <span class="editMaterial fz_20" @click="showEdit('ratify')"
            >修改</span
          >
          <span class="editMaterial fz_20" @click="showEdit('inspect')"
            >详情</span
          >
        </div>
        <p class="tips">
          此膜片组合为方案设计推荐，如需更改可自行选择，方案批准后生效
        </p>
      </div>
      <div :class="confirmObj.initText ? 'mt18' : ''" class="con_foo">
        <span
          class="confirm_con_cancel flex-x-y-c curp main_theme_color_333"
          @click="confirmHide"
        >
          {{ confirmObj.backText }}
        </span>
        <span
          class="confirm_con_confirm flex-x-y-c curp ml32 main_theme_color_333"
          @click="confirmSubmit"
        >
          {{ confirmObj.okText }}
        </span>
      </div>
      <img
        @click="confirmHide"
        class="close curp"
        src="../../common/imgs/3d/icon_close.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    confirmObj: {
      type: Object,
      default: () => ({}),
    },
    showMaterial: {
      // type: Array,
      // default: () => ([])
      required: false,
    },
    showPic: {
      type: Boolean,
      default: true,
    },
    isOldCase: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirmSubmit() {
      this.$emit("confirmSubmit");
    },
    confirmHide() {
      this.$emit("confirmHide");
    },
    showEdit(flag) {
      this.$emit("aStaging", flag);
      this.confirmHide();
    },
  },
};
</script>

<style scoped lang="scss">
.close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.confirm_box {
  position: fixed;
  overflow: hidden;
  z-index: 4000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.3);

  .confirm_con {
    //width: 4.58rem;
    width: 500px;
    //padding: 0 0.2rem;
    padding: 20px 40px;
    border: 1px solid #828282;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    //height: 2.88rem;
    min-height: 368px;
    background: rgba(46, 47, 48, 0.9);
    border-radius: 0.1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .con_pic {
      width: 0.54rem;
      height: 0.54rem;
      margin-bottom: 0.32rem;
      background-size: 100% 100%;
    }

    .con_pic_warning {
      background-image: url("./img/modal_warning.png");
    }

    .con_tip {
      max-width: 80%;
      font-size: 0.16rem;
      text-align: center;
      line-height: 0.26rem;
      color: white;
    }

    .con_txt {
      width: 70%;
      margin: 0 auto;
      font-size: 0.16rem;
      line-height: 0.24rem;
    }

    .con_foo {
      margin-top: 0.51rem;
      display: flex;
      align-items: center;
    }
  }

  .confirm_con_old {
    background-color: white;
  }
}

.confirm_con_cancel {
  width: 96px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #bbbbbb;
}

.confirm_con_confirm {
  width: 96px;
  height: 36px;
  background: #f2bf3e;
  border-radius: 4px;
}

.material {
  padding: 16px;
  width: 420px;
  min-height: 96px;
  background: #58595a;
  border-radius: 8px;
  margin-top: 24px;
  text-align: left;
  color: #fff;
  font-size: 16px;

  .tips {
    width: 100%;
  }

  .combined {
    display: flex;

    .combined-title {
      width: 100px;
    }
  }

  .material-detail {
    padding-top: 5px;
  }

  p {
    margin-top: 20px;
    margin-right: 0;
    width: 240px;
    height: 16px;
    font-size: 12px;
    color: #999999;
    line-height: 16px;
  }

  .editMaterial {
    color: #fcc80e;
    cursor: pointer;
    padding: 2px;
  }
}
</style>
