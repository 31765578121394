<template>
  <div class="preview_img_container">
    <div class="dsd_view" id="dsd_view">
      <img
        style="display: none"
        v-for="(it, idx) in carouselList"
        :src="`${$PicPrefix}${it['fileUrl']}`"
        :key="idx"
        alt=""
      />
    </div>
    <div class="thumbnail-container">
      <div
        class="thumbnail-item"
        v-for="(it, idx) in carouselList"
        @click="jumpIdx(idx)"
        :key="idx"
      >
        <div class="mask">
          <img
            :src="`${$PicPrefix}${it['fileUrl']}`"
            class="thumbnail-img"
            alt=""
          />
        </div>
        <div v-if="currentIdx == idx" class="thumbnail-changed"></div>
      </div>
    </div>
    <div @click="prev" class="prev option_btn">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div @click="next" class="next option_btn">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
import { downImage } from "common/js/util";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      visible: false, // 是否显示当前组件
      svgColor: false, // 关闭按钮颜色
      imgView: {}, // 图片node
      viewer: {}, // 组件node
      isHorizontal: false, // 水平向
      isVertical: false, // 垂直向
      currentName: "", // 当前图片名称
      currentIdx: 0, // 当前图片下标
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      language: (state) => state.language,
    }),
  },
  components: {
    Viewer,
  },
  props: {
    carouselList: {
      // item 必须要有 name key选传
      type: Array,
      default: [],
      required: true,
    },
    isSelected: {
      type: Boolean,
    },
  },
  watch: {
    isSelected: {
      handler: function (nval) {
        const viewerImage = this.viewer.image;
        viewerImage.style.marginLeft = "";
      },
    },
  },
  mounted() {
    const ViewerDom = document.getElementById("dsd_view");
    const _this = this;
    this.viewer = new Viewer(ViewerDom, {
      inline: true,
      button: false,
      toolbar: false,
      title: false,
      navbar: false,
      transition: false,
      initialViewIndex: 0,
      zoomOnWheel: true,
      className: "viewer_preview",
      viewed() {
        const viewerContainer = this.viewer.viewer;
        const viewerImage = this.viewer.image;
        viewerContainer.style.width = "100%";
        $(viewerContainer).css("background", "rgba(46, 47, 48, 0.9)");
        _this.imgView = this.viewer["image"];
        $(this.imgView).mouseenter(() => {});
      },
      zoom() {
        $(this.imgView).css("transition", ".4s linear");
        this.viewer.transition = true;
      },
      move() {
        $(this.imgView).css("transition", ".4s linear");
        this.viewer.transition = true;
      },
    });
    this.viewer.show();
  },
  methods: {
    onMouseWheel(e) {
      const dir = e.wheelDelta;
      $(this.imgView).css("transition", ".4s linear");
      if (dir > 0) {
        this.viewer.zoom(0.05, true);
      }
      if (dir < 0) {
        this.viewer.zoom(-0.05, true);
      }
    },
    jumpIdx(idx) {
      this.currentIdx = idx;
      this.viewer.view(idx);
    },
    prev() {
      $(this.imgView).css("transition", "none");
      if (this.currentIdx == 0) {
        this.currentIdx = this.carouselList.length - 1;
        this.viewer.view(this.carouselList.length - 1);
      } else {
        this.viewer.prev();
        this.currentIdx = this.viewer.index;
      }
    },
    next() {
      $(this.imgView).css("transition", "none");
      if (this.currentIdx == this.carouselList.length - 1) {
        this.currentIdx = 0;
        this.viewer.view(0);
      } else {
        this.viewer.next();
        this.currentIdx = this.viewer.index;
      }
    },
  },
};
</script>

<style lang="scss">
.preview_img_container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  .dsd_view {
    width: 100%;
  }
}
.option_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 64px;
  height: 64px;
  cursor: pointer;
  border-radius: 50%;
  background: #444;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: #fff;

  &:hover {
    background: #999;
    border: 2px solid #fff;
  }
}
.prev {
  left: 100px;
}
.next {
  right: 100px;
}
.thumbnail-container {
  width: 720px;
  height: 108px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  position: absolute;
  bottom: 48px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding: 0 12px 0;
  justify-content: center;
  align-items: center;

  .thumbnail-item {
    height: 100%;
    flex: 1;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    .mask {
      width: 100%;
      height: 72px;
      padding: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background-color: rgba($color: #ffffff, $alpha: 0.24);
      border-radius: 10px;
      .thumbnail-img {
        height: 100%;
      }
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  .thumbnail-changed {
    position: absolute;
    width: 16px;
    height: 4px;
    border-radius: 2px;
    background: #3789be;
    bottom: 6px;
  }
}

/deep/ .transition {
  transition: 0.4s linear;
}
/deep/ .viewer-backdrop {
  background: rgba(46, 47, 48, 0.9);
  // backdrop-filter: blur(4px);
}
</style>